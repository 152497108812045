
import { Component, Vue } from "nuxt-property-decorator";
import PersonalIncomeTaxReturn from "~/components/PersonalIncomeTaxReturn.vue";
import { AuthInfo } from "~/components/authInfo";
import { setBackgroundColor } from "~/components/color.util";

@Component({
  components: { PersonalIncomeTaxReturn },
})
export default class Index extends Vue {
  hasApiKey = false;
  loaded = false;

  head() {
    return {
      title: this.$i18n.t("homepage.page_title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$i18n.t("homepage.page_description"),
        },
      ],
    };
  }

  mounted() {
    setBackgroundColor("#fafafa");

    if (AuthInfo.getApiKey() === "") {
      AuthInfo.setApiKey(localStorage.getItem("pitApiKey") ?? "");
    }
    if (AuthInfo.getApiKey() !== "") {
      this.hasApiKey = true;
    }
    this.loaded = true;
  }

  get queryInput(): string | undefined {
    return this.$route.query.input
      ? Buffer.from(this.$route.query.input! as string, "base64").toString("utf-8")
      : undefined;
  }
}
