
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { mdiMagnify } from "@mdi/js";
import City from "@/components/input/city";
import SectionInfo from "@/components/input/sectionInfo";
import CodeInfo from "@/components/input/codeInfo";

@Component
export default class CodeSearchBarView extends Vue {
  @Prop({ default: true, type: Boolean })
  readonly active!: boolean;

  @Prop({ type: Object })
  readonly searchedCode!: any | null;

  @Prop({ default: false, type: Boolean })
  readonly isDoubleReturn!: boolean;

  @Prop({ required: true, type: Map })
  readonly sectionInfoForCurrentRegion!: Map<string, SectionInfo>;

  @Prop({ required: true, type: Map })
  readonly codeInfoForCurrentRegion!: Map<string, CodeInfo>;

  @Prop({ default: false, type: Boolean })
  readonly useNewUi!: boolean;

  mounted() {
    window.addEventListener("keydown", (ev) => {
      if (this.active && ev.key === "/") {
        ev.preventDefault();
        ev.stopPropagation();
        this.$nextTick(() => {
          if (this.$refs.codeSearchBar) {
            (this.$refs.codeSearchBar as HTMLElement).focus({
              preventScroll: true,
            });
            // @ts-ignore
            this.$refs.codeSearchBar.isMenuActive = true;
          }
        });
      }
    });
  }

  get searchedCodeInternal() {
    return this.searchedCode;
  }

  set searchedCodeInternal(code: string | null) {
    this.$emit("update:searchedCode", code);
  }

  codeInfoType = CodeInfo;

  mdiMagnify = mdiMagnify;

  get searchBarItems(): (CodeInfo | SectionInfo)[] | null {
    const sectionInfoItems = Array.from(this.sectionInfoForCurrentRegion.values())
      .filter((x) => x.id !== "0")
      .sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
    const codeInfoItems = Array.from(this.codeInfoForCurrentRegion.values())
      .filter((x) => !x.invisible)
      .filter((x) => x.code !== "unknown")
      .filter((x) => !x.isSumTerm)
      .filter((x) => (this.isDoubleReturn ? true : !x.isPartner))
      .sort((a, b) => a.code.localeCompare(b.code));
    return [...sectionInfoItems, ...codeInfoItems];
  }

  filterSearch: Object = (_item: City, queryText: string, itemText: string) => {
    // const itemTextUpdated = /[A-Z]/.test(queryText) ? itemText : itemText.toLowerCase();
    return queryText.split(" ").every((x) => itemText.toLowerCase().includes(x));
  };

  onSearchSectionOrCode(codeOrSectionInfo: CodeInfo | SectionInfo | null | undefined) {
    if (codeOrSectionInfo !== null && codeOrSectionInfo !== undefined) {
      if (codeOrSectionInfo instanceof this.codeInfoType) {
        this.$emit("clickOnCode", (codeOrSectionInfo as CodeInfo).code);
      } else {
        this.$emit("clickOnSection", (codeOrSectionInfo as SectionInfo).id);
      }
    }
  }
}
