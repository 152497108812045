import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=71f9e3f0&scoped=true&"
import script from "./Footer.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Footer.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=71f9e3f0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f9e3f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/opt/buildhome/repo/components/Logo.vue').default})
