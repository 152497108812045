
import groupBy from "lodash.groupby";
import { Component, Prop, Vue } from "nuxt-property-decorator";
import {
  mdiAlert,
  mdiAlertCircle,
  mdiMagnify,
  mdiChevronUp,
  mdiChevronDown,
  mdiHomeCity,
  mdiHumanMaleFemaleChild,
  mdiHandCoin,
  mdiCloseCircleOutline,
} from "@mdi/js";
import CodeItemsEditorView from "./CodeItemsEditorView.vue";
import SavingsWizardView from "./wizards/savings/SavingsWizardView.vue";
import Household from "./wizards/household/household";
import CodeItem from "@/components/input/codeItem";
import City from "@/components/input/city";
import SimulationInput from "@/components/input/simulationInput";
import SimulationInputCodeView from "@/components/input/SimulationInputCodeView.vue";
import SimulationInputYearView from "@/components/input/SimulationInputYearView.vue";
import SimulationInputCityView from "@/components/input/SimulationInputCityView.vue";
import CodeSearchBarView from "@/components/input/CodeSearchBarView.vue";
import ErrorCollectionView from "@/components/input/ErrorCollectionView.vue";
import Logo from "@/components/Logo.vue";
import SimulationResultSummary from "@/components/result/SimulationResultSummary.vue";
import Simulation from "@/components/simulation";
import RealEstateAndLoansWizardView from "@/components/input/wizards/realEstateAndLoans/RealEstateAndLoansWizardView.vue";
import RealEstateAndLoans from "~/components/input/wizards/realEstateAndLoans/realEstateAndLoans";
import Savings from "~/components/input/wizards/savings/savings";
import HouseholdWizardView from "~/components/input/wizards/household/HouseholdWizardView.vue";
import PersonView from "~/components/input/wizards/household/PersonView.vue";

@Component({
  components: {
    PersonView,
    Logo,
    ErrorCollectionView,
    CodeSearchBarView,
    SimulationInputCodeView,
    CodeItemsEditorView,
    SimulationInputYearView,
    SimulationInputCityView,
    SimulationResultSummary,
    RealEstateAndLoansWizardView,
    SavingsWizardView,
    HouseholdWizardView,
  },
})
export default class SimulationInputView extends Vue {
  @Prop({ default: false, type: Boolean })
  readonly active!: boolean;

  @Prop({ default: 0, type: Number })
  readonly headerHeight!: number;

  @Prop({ required: true, type: Object })
  readonly simulation!: Simulation;

  @Prop({ default: null, type: Object })
  readonly realEstateAndLoans!: RealEstateAndLoans;

  @Prop({ default: null, type: Object })
  readonly savings!: Savings;

  @Prop({ default: null, type: Object })
  readonly household!: Household;

  @Prop({ default: null, type: Object })
  readonly simulationForComparing!: Simulation | null;

  @Prop({ default: null, type: String })
  readonly remoteError!: string | null;

  @Prop({ default: false, type: Boolean })
  readonly editable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly validateRequiredAdditionalInfo!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showOnlyCodesWithValueIfNotEditable!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showDiff!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly showWizards!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly hideComments!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly collapseAdministrativeInfo!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly useNewUi!: boolean;

  validYears = SimulationInput.validYears;

  incompleteYears = SimulationInput.incompleteYears;

  /*
   * INITIALIZATION OF DATA
   */

  mounted() {
    this.administrativeInfoSectionExpanded = !this.collapseAdministrativeInfo;
  }

  get panels() {
    return [...Array(this.codeItemsGroupedBySectionTuples.length).keys()];
  }

  get wizardsEnabled(): string[] {
    const wizards = [];
    if (this.realEstateAndLoans?.input.enabled) {
      wizards.push("real_estate_and_loans");
    }
    if (this.savings?.hasValue) {
      wizards.push("savings");
    }
    if (this.household?.enabled) {
      wizards.push("household");
    }
    return wizards;
  }

  /*
   * CURRENT STATE
   */

  get codeItemsGroupedBySectionTuples(): any {
    const getItem = (itemCompare: CodeItem) => {
      if (this.simulation.input.codeItems?.has(itemCompare.info.code)) {
        return this.simulation.input.codeItems.get(itemCompare.info.code);
      } else if (this.simulation.input.codeInfoForCurrentRegion?.has(itemCompare.info.code)) {
        return new CodeItem(this.simulation.input.codeInfoForCurrentRegion!.get(itemCompare.info.code)!, null, []);
      } else {
        return null;
      }
    };

    if (this.simulation && this.simulation.input.codeItems) {
      const allCodes = this.hideComments
        ? new Set(
            Array.from(this.simulation.input.codeItemsWithValue!.keys()).concat(
              this.simulationForComparing?.input.codeItemsWithValue!
                ? Array.from(this.simulationForComparing.input.codeItemsWithValue!.keys())
                : []
            )
          )
        : new Set(
            Array.from(this.simulation.input.codeItemsWithValueOrCommentOrTodo!.keys()).concat(
              this.simulationForComparing?.input.codeItemsWithValueOrCommentOrTodo
                ? Array.from(this.simulationForComparing.input.codeItemsWithValueOrCommentOrTodo.keys())
                : []
            )
          );
      const allCodeItemsObject = Array.from(allCodes)
        .map((code) => [
          this.simulation.input.codeItems!.get(code),
          this.simulationForComparing?.input.codeItems?.get(code),
        ])
        .map(([itemCurrent, itemCompare]: (CodeItem | undefined)[]) => {
          const item = itemCurrent || getItem(itemCompare!);
          return {
            code: itemCurrent ? itemCurrent.info.code : itemCompare?.info.code,
            index: item ? item.info.index : itemCompare?.info.index,
            sectionId: itemCurrent ? itemCurrent.info.section.id : itemCompare?.info.section.id,
            itemCurrent: item,
            itemCompare: this.simulationForComparing && !itemCompare ? null : itemCompare,
          };
        });
      return groupBy(allCodeItemsObject, "sectionId");
    }
    return {};
  }

  updateCodes(collection: CodeItem[], wizardsEnabled?: string[]) {
    const simulationInput = this.simulation.input.newInputFromCollection(collection);
    if (wizardsEnabled !== undefined) {
      simulationInput.enabledWizards = wizardsEnabled;
      this.simulation.input.enabledWizards.forEach((wizard) => {
        if (!wizardsEnabled.includes(wizard)) {
          simulationInput.disableWizard(wizard);
        }
      });
      simulationInput.update();
    }
    this.updateSimulationInput(simulationInput);
  }

  updateCodeItemFromComparison(codeItem: CodeItem) {
    // also update all term sum components
    if (
      this.simulationForComparing &&
      this.simulationForComparing.input.codeItems &&
      codeItem.info.sumFieldTotal &&
      this.simulation.input.codeItems
    ) {
      const existingSumTermsFromActive = Array.from(this.simulation.input.codeItems.entries())
        .filter(([, item]) => item.info.parentSumCode === codeItem.info.parentSumCode)
        .map(([, item]) => item);
      const removedSumTermsFromActive = existingSumTermsFromActive.map((item) => new CodeItem(item.info, null, []));

      const sumTermsFromComparison = Array.from(this.simulationForComparing.input.codeItems.entries())
        .filter(([, item]) => item.info.parentSumCode === codeItem.info.parentSumCode)
        .map(([, item]) => item);
      const sumTermsFromComparisonSet = new Set(sumTermsFromComparison.map((item) => item.info.code));
      const removedSumTermsForCollection = removedSumTermsFromActive.filter(
        (item) => !sumTermsFromComparisonSet.has(item.info.code)
      );

      this.updateSimulationInput(
        this.simulation.input.newInputFromCollection([
          codeItem,
          ...sumTermsFromComparison,
          ...removedSumTermsForCollection,
        ])
      );
    } else {
      this.updateSimulationInput(this.simulation.input.newInputFromCollection([codeItem]));
    }
  }

  removeCode(codeId: string) {
    const newState = this.simulation.input.clone();
    newState.setUserCommentForCode(codeId, undefined, false);
    newState.setTagsForCode(codeId, [], false);
    newState.removeCode(codeId, false);
    newState.update();
    this.updateSimulationInput(newState);
  }

  saveComment(codeItem: CodeItem) {
    const newState = this.simulation.input.clone();
    newState.setUserCommentForCode(codeItem.info.code, codeItem.userComment);
    newState.setTagsForCode(codeItem.info.code, codeItem.tags);
    this.updateSimulationInput(newState);
  }

  updateTaxYear(taxYear: number) {
    const newState = this.simulation.input.clone();
    newState.taxYear = taxYear;
    this.updateSimulationInput(newState);

    const el = this.$el.querySelector(":focus");
    if (el) {
      (el as HTMLElement).blur();
    }
  }

  updateCity(city: City | null) {
    const newState = this.simulation.input.clone();
    newState.cityObject = city;
    this.updateSimulationInput(newState);
  }

  updateSimulationInput(newState: SimulationInput, realEstateAndLoans?: RealEstateAndLoans) {
    if (!this.simulation.input || !this.simulation.input.equals(newState)) {
      this.$emit("update-input", newState, realEstateAndLoans);
    } else {
      this.$emit("activateCancelledSnack");
    }
  }

  loadCodes(collection: Map<string, number>) {
    const newState = this.simulation.input.clone();
    collection.forEach((value, key) => {
      newState.setCode(key, value);
    });
    this.updateSimulationInput(newState);
  }

  get calculationWarnings(): Map<string, string[]> {
    const warnings = new Map<string, string[]>();
    if (this.simulation.isUpToDate && this.simulation.result && this.simulation.result.warnings) {
      this.simulation.result.warnings.forEach((warning) => {
        warning.codes.forEach((code) => {
          if (warnings.has(code)) {
            warnings.set(code, warnings.get(code)!.concat(warning.warningId));
          } else {
            warnings.set(code, [warning.warningId]);
          }
        });
      });
    }
    return warnings;
  }

  /*
   * UI Functions
   */

  mdiAlert = mdiAlert;

  mdiAlertCircle = mdiAlertCircle;

  mdiMagnify = mdiMagnify;

  mdiChevronUp = mdiChevronUp;

  mdiChevronDown = mdiChevronDown;

  mdiHomeCity = mdiHomeCity;

  mdiHumanMaleFemaleChild = mdiHumanMaleFemaleChild;

  mdiHandCoin = mdiHandCoin;

  mdiCloseCircleOutline = mdiCloseCircleOutline;

  administrativeInfoSectionExpanded = true;

  searchedCode: Object | null = null;

  dialogActive = false;

  editorActive = false;

  displaySection = false;

  findDeep = false;

  codesForDialog: string[] = [];

  codeToFocus: string | null = null;

  foreignIndexToFocus: number | null = null;

  foreignTypeToFocus: string | null = null;

  editorLoading = false;

  wizardInput = false;

  householdWizard = false;

  realEstateAndLoansWizard = false;

  savingsWizard = false;

  clickOnSection(sectionId: string) {
    const codeInfoForCurrentRegion = this.simulation.input.codeInfoForCurrentRegion;
    if (codeInfoForCurrentRegion !== null && sectionId !== "0") {
      this.displaySection = true;
      this.codesForDialog = new Array(...codeInfoForCurrentRegion.keys()).filter(
        (code) =>
          codeInfoForCurrentRegion!.get(code)!.section.id === sectionId &&
          (this.simulation.input.isDoubleReturn || !codeInfoForCurrentRegion!.get(code)!.isPartner)
      );
      this.codesForDialog.sort(
        (a, b) => codeInfoForCurrentRegion!.get(a)!.index - codeInfoForCurrentRegion!.get(b)!.index
      );
      [this.codeToFocus] = this.codesForDialog;
      setTimeout(() => {
        this.editorActive = true;
      }, 0);
      this.editorLoading = true;
    }
  }

  clickOnCode(codeId: string, fromError = false) {
    if (codeId == "global1" || (fromError && ["1061", "1090"].includes(codeId))) {
      this.$nextTick(() => {
        if (this.$refs.cityRow) {
          ((this.$refs.cityRow as any)[0] as SimulationInputCityView).enableEditMode();
        }
      });
    } else if (this.simulation.input.staticInfo && this.simulation.input.codeInfoForCurrentRegion!.has(codeId)) {
      this.codesForDialog = [codeId];
      this.codeToFocus = codeId;
      this.findDeep = true;
      this.foreignIndexToFocus = null;
      this.foreignTypeToFocus = null;
      this.$nextTick(() => {
        this.editorActive = true;
      });
      this.editorLoading = true;
    }
  }

  showEntireSectionInDialog(sectionId: string) {
    const codeInfoForCurrentRegion = this.simulation.input.codeInfoForCurrentRegion;
    if (codeInfoForCurrentRegion !== null && sectionId !== "0") {
      this.codesForDialog = new Array(...codeInfoForCurrentRegion.keys())
        .filter(
          (code) =>
            codeInfoForCurrentRegion!.get(code)!.section.id === sectionId &&
            (this.simulation.input.isDoubleReturn || !codeInfoForCurrentRegion!.get(code)!.isPartner)
        )
        .sort((a, b) => codeInfoForCurrentRegion!.get(a)!.index - codeInfoForCurrentRegion!.get(b)!.index);
      this.displaySection = true;
    }
  }

  clickOnForeignItem(codeId: string, index: number, type: string) {
    this.codesForDialog = [codeId];
    this.codeToFocus = codeId;
    this.findDeep = true;
    this.foreignIndexToFocus = index;
    this.foreignTypeToFocus = type;
    this.$nextTick(() => {
      this.editorActive = true;
    });
    this.editorLoading = true;
  }

  lockedByWizards(codeItem: CodeItem | null): string[] {
    return codeItem?.tags.filter((tag) => tag.startsWith("from_wizard:")).map((tag) => tag.slice(12)) || [];
  }

  possiblyImpactedByWizard(codeItem: CodeItem | null): string[] {
    if (!codeItem || this.wizardsEnabled.length === 0 || codeItem.info.wizards.length === 0) {
      return [];
    }
    const result = [];
    for (const wizard of this.wizardsEnabled) {
      if (codeItem.info.wizards.includes(wizard)) {
        result.push(wizard);
      }
    }
    return result;
  }

  onEditorLoaded() {
    // in order to show progress indicator
    this.editorLoading = false;
    this.dialogActive = true;
  }

  closeDialog() {
    this.dialogActive = false;
    this.editorLoading = false;
    this.editorActive = false;
    this.displaySection = false;
    this.codeToFocus = null;
    this.findDeep = false;
    this.searchedCode = null;
    this.wizardInput = false;
    this.realEstateAndLoansWizard = false;
    this.savingsWizard = false;
    this.$emit("activateCancelledSnack");
  }

  get codeSearchBarContainerStyles() {
    return {
      top: `${this.headerHeight}px`,
      paddingBottom: this.useNewUi ? "24px" : "",
      display: this.useNewUi ? "flex" : "",
      columnGap: this.useNewUi ? "50px" : "",
    };
  }

  get wizardButtonContainerStyles() {
    return {
      order: this.useNewUi ? "calc(infinity)" : "",
      columnGap: this.useNewUi ? "4px" : "",
      maxWidth: "fit-content",
    };
  }

  get wizardButtonStyles() {
    return {
      borderRadius: !this.useNewUi ? "4px" : "",
      height: !this.useNewUi ? "38px" : "",
      position: !this.useNewUi ? "relative" : "",
      color: this.useNewUi ? "#404040" : "",
    };
  }

  wizardButtonColor(condition: boolean) {
    if (condition) return "primary";

    return this.useNewUi ? "secondary" : "gray";
  }

  get pitInputViewContentStyles() {
    return {
      borderRadius: this.useNewUi ? "8px" : "",
    };
  }
}
